import {
    Highlight,
    Hits,
    InstantSearch,
    SearchBox,
    connectStateResults
  } from "react-instantsearch-dom"
  
  import React from "react"
  import algoliasearch from 'algoliasearch/lite';
  
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
  )
  
  const Search = () => {
    const Results = connectStateResults(({ searchState, searchResults, children }) =>
      searchState && searchState.query && searchResults && searchResults.nbHits !== 0 ? (
        <div className="absolute top-full w-full p-2 bg-gray-200 shadow-md">
          {children}
        </div>
      ) : (
        <div></div>
      )
    );
  
    return (
      <div className="relative">
        <InstantSearch indexName={process.env.GATSBY_SEARCH_INDEX_NAME} searchClient={searchClient}>
          <SearchBox submit={null} reset={null} />
          <Results>
            <Hits hitComponent={Hit} />
          </Results>
        </InstantSearch>
      </div>
    )
  }
  
  const Hit = ({ hit }) => {
    return (
      <div key={hit.id} className="relative">
        <div className="hit-name">
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </div>
      </div>
    )
  }
  
  export default Search;